:root {
  --bg: rgb(26, 26, 28);
  --menu: rgb(18, 18, 20);
  --drk: rgb(18, 18, 20);
  --primary: #5358de;
  --link: #6871e6;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #eee !important;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  overflow-y: hidden;
}

table th {
  opacity: 0.8;
}

.MuiTabs-fixed {
  height: 40px !important;
}

.MuiTab-root {
  color: #eee !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 20px !important;
}

.MuiPaper-root {
  background-color: var(--bg) !important;
}

.MuiTab-root[aria-selected="true"] {
  color: #388ce0 !important;
}

.MuiTab-root[aria-selected="false"] {
  color: rgb(165, 165, 165) !important;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

img {
  font-size: 0px;
}

.app {
  display: flex;
  flex-direction: row-reverse;
}

.auth-required {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.auth-required .prompt {
  width: fit-content;
  padding: 12px 20px;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
}

.auth-required .prompt h5 {
  font-size: 1.4em;
}

.auth-required #background {
  backdrop-filter: blur(10px);
  overflow-x: hidden;
  rotate: 180deg;
  overflow-y: hidden;
  min-height: 20vh;
  transform: translateY(20px);
}

.auth-required img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  background-position: 50% 50%;
}

.hell-menu {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.hell-menu nav {
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 6px 12px;
  background-color: var(--menu);
  box-shadow: 1px 0 8px rgba(0, 0, 0, 0.8);
  padding-top: 28px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.hell-menu nav a,
.hell-menu nav button {
  text-decoration: none;
  color: rgb(185, 185, 185) !important;
  margin: 0 8px 0 0;
  background-color: var(--menu);
  font-size: 20px;
  padding: 6px 14px !important;
  margin: 2px 0;
  border-left: 4px solid transparent;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
}

.hell-menu nav a:hover,
.hell-menu nav button:hover {
  filter: brightness(1.45);
}

.hell-menu nav a.active,
.hell-menu nav button:active {
  color: var(--link) !important;
}

.hell-menu nav a.active::before,
.hell-menu nav button:active::before {
  position: absolute;
  left: 0px;
  content: '';
  background-color: var(--link) !important;
  width: 6px;
  height: 42px;
  border-radius: 3px;
  border-left: 4px solid var(--link);
}

.hell-menu a i,
.hell-menu button i {
  margin-right: 10px;
}

h3.title {
  font-size: 24px;
  opacity: 0.9;
  margin-bottom: 16px !important;
}

button,
*[role="button"],
input[type="submit"] {
  border: none;
  border-radius: 2px;
  padding: 6px 18px;
  color: white;
  background-color: var(--primary);
  max-height: 40px;
  user-select: none;
  text-align: center;
  text-decoration: none;
  outline: none;
  transition: .1s;
}

button:disabled {
  background-color: #363636;
  cursor: not-allowed;
}

a#login:active,
a#login:hover,
.auth-required button:active,
.auth-required button:hover {
  color: white;
  box-shadow: 0 0 0 3px rgba(55, 66, 154, 0.7);
}

.main {
  position: relative;
  display: block;
  width: 90%;
  display: block;
  margin: 0 auto;
  padding: 0 16px;
  padding-top: 25px;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 120px;
}

.main.disable-scroll {
  overflow: hidden !important;
}

.members {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
}

.member-item {
  border-radius: 40px !important;
  margin: 4px 0;
  overflow: hidden;
}

.member-item[open]::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.member-item strong {
  opacity: 0.86;
}

.member-item summary {
  background-color: var(--drk);
  list-style: none;
  border-radius: 4px !important;
}

.member-item .summary-header {
  background-color: var(--drk);
  padding: 4px 8px;
  position: relative;
  border-radius: 8px!important;
}

.member-item .summary-header:hover {
  filter: brightness(1.225);
  cursor: pointer;
}

.member-item .drawer {
  position: fixed;
  width: 500px;
  max-width: 80vw;
  padding: 32px 22px;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  overflow-y: auto;
  background-color: var(--bg);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 1);
}

.member-item img {
  margin: 3px 0;
  margin-right: 8px;
  font-size: 0px;
  user-select: none;
  border-radius: 50% !important;
  width: 42px;
  height: 42px;
}

.member-item .drawer img {
  margin: 0;
}

.members .member-item button {
  border: none;
  background-color: #1c1e20;
  color: white;
  padding: 12px 24px;
  border: 2px solid #444;
  margin: 0 6px;
  border-radius: 6px;
  opacity: 0.6;
  font-weight: 300;
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  transition: .18s;
}

.members .member-item button:hover,
.members .member-item button:active {
  filter: brightness(1.4);
  border: 2px solid var(--primary);
}

.members .member-item button:disabled {
  background-color: rgb(65, 65, 65);
  border: 2px solid #666;
  cursor: not-allowed;
}

.member-item #vote-date {
  background-color: rgb(15, 15, 15);
  border-radius: 3px;
  padding: 1px 4px;
  color: #cacaca;
  font-size: 14px;
}

.members .member-item .icon {
  color: rgb(102, 102, 102);
  cursor: default;
  margin-left: 8px;
}

.members .member-item i:hover {
  cursor: pointer;
  color: tomato;
}

.member-item .drawer .ul::after {
  content: "Votos";
  position: absolute;
  top: 0px;
  height: 40px;
  padding: 3px 16px 0 0;
  color: #bebebe;
  width: fit-content;
  z-index: 120;
}

.member-item .drawer .ul {
  position: relative;
  border-top: 2px solid #444 !important;
  margin: 0;
  margin-top: 20px;
}

.member-item .drawer .ul img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: var(--drk);
}

.member-item .drawer .ul li {
  width: 100%;
  margin-top: 4px;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}

.profile-details .profile-header {
  display: flex;
  align-items: flex-start !important;
}

.profile-details .profile-header img {
  border-radius: 50%;
  width: 64px;
  height: 64px;
}

.profile-details button {
  border-radius: 20px !important;
  background-color: #0b0b0b !important;
  border: 1px solid #303030 !important;
}

.roles {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 10px;
}

.role-item {
  opacity: 0.95;
  font-size: 13px;
  text-shadow: 0 1px 6px #000, 0 1px 3px #000;
  color: #fff;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 2px;
  user-select: none;
}

small {
  display: block;
  opacity: 0.8;
  font-size: 12px;
}

.adptive {
  margin: 0 32px !important;
  display: flex !important;
  justify-content: center !important;
}

.hell-menu-mobile {
  display: none;
}

button#votar,
a#votar {
  opacity: 1 !important;
  padding: 7px 18px;
  border-radius: 8px !important;
  font-weight: bold;
  font-size: 12px;
  border: 2px solid var(--link) !important;
  background-color: var(--primary) !important;
  text-transform: uppercase;
  color: #fff !important;
}

button#votar:active,
a#votar:active,
button#votar:hover,
a#votar:hover {
  filter: brightness(1.1);
}

@media only screen and (min-width: 1380px) {
  .hell-menu nav {
    width: 290px;
  }
}

@media screen and (min-width: 801px) {
  .hide-md {
    display: none !important;
  }
}

@media only screen and (max-width: 780px) {
  .member-item .drawer {
    position: fixed;
    z-index: 99999;
    width: 100%;
    max-width: 100%;
    top: 40% !important;
    bottom: 0 !important;
    height: 60%;
    border-radius: 12px 12px 0 0;
    padding: 4px 0;
    padding-bottom: 0;
  }

  .member-item .drawer .votes-list {
    margin: 0 9px;
  }

  .member-item .drawer .profile-details {
    padding: 16px 16px;
  }

  .member-item .drawer img {
    height: 48px;
    width: 48px;
  }

  .member-item .drawer h4 {
    font-size: 18px;
    margin: 2px 0;
  }

  .hell-toast {
    bottom: 72px !important;
  }

  .auth-required {
    overflow-x: hidden;
  }

  .auth-required #background {
    width: 780px;
  }

  .hell-menu-mobile {
    display: none;
    flex-direction: column;
    background-color: rgb(48, 48, 50);
    width: 300px;
    padding: 26px 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100;
    min-height: 100% !important;
    z-index: 100 !important;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.6);
  }

  .hell-menu-mobile::before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
  }

  .hell-menu-mobile .user-float {
    position: absolute;
    margin-top: 20px;
    bottom: 18px;
    left: 12px;
  }

  .hell-menu-mobile #login {
    color: white !important;
    position: absolute;
    font-size: 18px;
    opacity: 0.75;
    bottom: 32px;
    padding: 6px 12px;
    left: 12px;
  }

  .hell-menu-mobile li {
    padding: 6px 16px;
    margin-top: 2px;
    list-style: none;
  }

  .hell-menu-mobile a, .hell-menu-mobile button {
    background-color: transparent;
    padding: 0px 6px;
    color: #969696;
    user-select: none;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 23px;
  }

  .hell-menu-mobile a i, .hell-menu-mobile button i {
    margin-right: 12px;
  }

  .hell-menu-mobile a.active {
    color: var(--link);
  }

  .hell-menu-mobile .user-info {
    display: flex;
    align-items: center;
    background-color: #090a0ad2;
    border-radius: 60px;
    width: fit-content;
    position: static !important;
    margin-bottom: 5px;
  }

  ::-webkit-scrollbar {
    width: 6px !important;
  }

  .hell-menu {
    position: fixed;
    z-index: 2000;
    height: 100vh;
  }

  .hell-menu nav {
    background-color: var(--menu) !important;
    height: 100vh;
  }

  .user-text {
    font-size: 15px;
  }

  .rank-item small.first::after {
    display: none;
  }

  nav {
    padding: 8px 20px;
    height: 52px;
  }

  nav a {
    font-size: 18px;
    margin: 0 12px 0 0;
  }

  .member-item .drawer .ul {
    padding: 0 4px !important;
  }

  .main {
    width: 100%;
    padding: 0 6px;
    padding-top: 25px;
    padding-bottom: 50px !important;
  }

  .hide-sm {
    display: none !important;
  }

  .adptive {
    margin: 0 !important;
  }

  .members {
    margin-bottom: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 0px;
  }

  .hell-modal {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    outline: none;
    border-radius: 12px;
    background-color: var(--bg);
    min-height: 100px;
    overflow-y: auto;
    padding: 12px 16px;
    word-wrap: break-word;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: rgb(11, 11, 11);

}

::-webkit-scrollbar-thumb {
  background: rgb(60, 60, 60);
  border-radius: 3px;
}

input#search {
  width: 100%;
  background-color: var(--drk);
  border: none;
  outline: none;
  padding: 7px 8px;
  color: #eee;
  caret-color: white;
}

.search-wrapper {
  display: flex;
  align-items: stretch;
  background-color: var(--drk);
  border-radius: 6px;
  overflow: hidden;
  margin: 10px 0;
}

.search-wrapper button {
  border: none;
  background-color: transparent;
  color: #eee;
  outline: 0;
}

#boi-color {
  color: rgb(154, 123, 255);
  font-weight: bold;
}

.hell-menu #login {
  float: right;
  position: absolute;
  width: 91%;
  border-radius: 20px;
  background-color: var(--primary) !important;
  text-align: center !important;
  display: flex;
  justify-content: center;
  color: #fff !important;
  bottom: 20px;
}

.hell-toast {
  position: fixed;
  min-width: 200px;
  background-color: #0c0c0c;
  right: 20px;
  border-radius: 8px;
  bottom: 24px;
  z-index: 230;
}

.hell-toast span {
  display: block;
  padding: 10px 12px !important;
}

.rank-item img,
.history-item img {
  border-radius: 50%;
  margin: 3px 8px;
  font-size: 0px;
  user-select: none;
  width: 42px;
  height: 42px;
}

.rank-item small#days {
  display: inline-block;
  font-size: 16px;
  background-color: rgb(73, 73, 73);
  border-radius: 4px;
  padding: 0px 7px;
  margin-right: 5px;
}

.user-info {
  display: flex;
  align-items: center;
  background-color: #090a0ad2;
  border-radius: 60px;
  width: fit-content;
  position: absolute;
  bottom: 16px;
  cursor: pointer;
  left: 10px;
}

.user-info img,
.user-header {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 12px;
  border: 2px solid #111;
  display: inline-block;
}

.user-info p {
  margin-left: 12px;
}

.user-float {
  display: flex;
  align-items: center;
  user-select: none;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
}

#hell-popover-content {
  background-color: var(--bg);
  color: #ddd !important;
  max-width: 220px;
  min-width: 160px;
  filter: brightness(1.4);
}

#hell-popover-content li {
  background-color: transparent;
  list-style: none !important;
  padding: 9px 10px;
  border-radius: 6px;
  color: #dadada;
  display: flex;
  align-items: center;
}

#hell-popover-content a {
  text-decoration: none !important;
}

#hell-popover-content li i {
  margin-right: 8px;
}

#hell-popover-content li:hover {
  cursor: pointer !important;
  color: var(--primary) !important;
}

.hidden {
  display: none !important;
}

.hell-modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  outline: none;
  border-radius: 12px;
  background-color: var(--bg);
  min-height: 100px;
  overflow-y: auto;
  padding: 28px 20px;
  word-wrap: break-word;
}

.hell-modal button {
  border-radius: 28px;
}

.hell-modal button:hover {
  filter: brightness(1.2);
}

input[type="checkbox"] {
  transform: scale(1.32);
  margin-right: 12px;
  margin-left: 2px;
  position: relative;
}

input[type="checkbox"]::after {
  position: absolute;
  content: '';
  background-color: var(--bg);
  height: 16px;
  width: 16px;
  border: 2px solid #5e5e5e;
  border-radius: 2px;
  cursor: pointer;
}

input[type="checkbox"]:checked::after {
  background-color: var(--primary);
}

label {
  user-select: none;
  margin-top: 5px !important;
}

.form label {
  display: flex;
  flex-direction: column;
}

.form input,
select {
  background-color: #111;
  border: 2px solid #333;
  border-radius: 4px;
  padding: 8px 12px;
  height: 40px !important;
  caret-color: var(--link);
  color: #eee;
  outline: none;
}

.form input:focus {
  border: 2px solid var(--primary);
}

.form input:disabled {
  border: 2px solid #303030;
  background-color: #222;
}

input[type="color"] {
  width: 100%;
  padding: 0;
}

.marquee {
  width: 100%;
  color: #cccccc;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  margin: 0;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

#mobile-menu-toggle {
  transform: scale(1.2);
  position: absolute;
  right: 20px;
  background-color: #282828;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  bottom: 32px;
  z-index: 100;
  border-radius: 10px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#color-selection {
  position: relative;
  background-color: #111;
  border: 2px solid #444;
  padding: 6px 12px;
  border-radius: 6px;
  width: 136px;
}

#color-selection section {
  position: absolute;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  min-width: 100%;
  left: 0;
  overflow: hidden;
  top: 40px;
  background-color: #181818;
  z-index: 100;
}

#color-selection summary {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 14px;
}

#color-selection li {
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #181818;
  font-size: 14px;
  padding: 7px 14px;
}

#color-selection li:hover {
  filter: brightness(1.35);
}